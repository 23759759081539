import VanillaModal from 'vanilla-modal';

const modal = new VanillaModal({
	modal:'.modal'
});




export default function() {

	$('.signup-button > button')
    .on('click', function(e){
      e.preventDefault();
      modal.open('#modal-join');
	});
	
	
	/**
	$('.menu-contact a, .link-contact-us, a[href="#contact"]')
    .on('click', function(e){
      e.preventDefault();
      modal.open('#modal-contact');
	});
	
	$('.membership__package:not(".no-modal") .js-purchase-membership')
	.on('click', function(e){
      e.preventDefault();
      modal.open('#modal-membership-checkout');
      return false;
	});
	//if .no-modal on the package div, then just go to the url attr of the button
	$('.membership__package.no-modal .js-purchase-membership')
	.on('click', function(e){
      e.preventDefault();
      window.location = $(this).attr('data-button_url');
      return false;
	});
	
	$('.section__nav-link[href="#support"], a[href="#support"]')
	.on('click', function(e){
		console.log('open contact modal');
      e.preventDefault();
      if(modal.isOpen){
	      modal.close();
		  window.setTimeout(function(){
			  //modal.open('#modal-membership-login'); 
		  }, 500);
	  }else{
		 modal.open('#modal-membership-login'); 
	  }
      
	});
	**/

}
