import modal from './modal';
//import lightbox from './lightbox';
//import nav from './nav/menu';
//import slider from './slider';
//import scroll from './scroll';
//import notice from './notice';
import general from './general';

modal();
//lightbox();
//nav(); // The mainmenu and responsive behavior
//slider(); // swiper js
//scroll(); // ScrollSpy, Sticky Header, ScrollTo, etc. anything  related to scroll
//notice(); // Managing the notification (yellow bar on top of website)